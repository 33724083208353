import { Show, splitProps } from 'solid-js'

type Props = ComponentProps<'img'>

export function Img(props: Props) {
  const [, attr] = splitProps(props, ['children'])
  return (
    <Show keyed when={attr.src}>
      {src => (
        <img
          {...attr}
          alt={attr.alt ?? ''}
          decoding={attr.decoding ?? 'async'}
          loading={attr.loading ?? 'lazy'}
          src={src}
        />
      )}
    </Show>
  )
}
